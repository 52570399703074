import QRCode from 'qrcode'
import _isEqual from 'lodash.isequal'
import _debounce from 'lodash.debounce'
import _cloneDeep from 'lodash.clonedeep'
import _isNil from 'lodash.isnil'
import html2canvas from 'html2canvas'
import { formatDate, formatTime } from '@smarttransit/common'

import {
  updateTransportationProfile,
  getTransportationProfile,
  createTransportationProfile
} from '../../../../services/transportation-profiles-service'

import {
  registerTransportationEquipment,
  findTransportationEquipment,
  disconnectTransportationEquipment
} from '../../../../services/transportation-equipment-service'

import { findTransportationOwnerUsers } from '../../../../services/transportation-owner-users-service'
import { C_ADD_SITE_ALERT } from '../../../../utilities/mutation-types'
import { addAlert } from '../../../../utilities/helpers'
import featurePhoneImage from '../../../../assets/nokia-yam.png'
import smartPhoneImage from '../../../../assets/smartphone-pointer.png'
import logoImage from '../../../../assets/logo.png'
import { findStRoutes } from '../../../../services/st-routes-service'

export default {
  name: 'transportation-accounts-vehicle',
  props: {
    signedInUser: Object,
    selectedAccount: Object,
    vehicleId: [String, Number],
    setAsSelectedAccount: Function,
    hasUserType: Function,
    hasTransportationRole: Function,
    forceRootViewRefresh: Function,
    transportTypes: Array,
    agencies: Array
  },
  data () {
    return {
      currentVehicle: null,
      currentVehicleOriginal: null,
      apiInProgress: false,
      availableEquipment: [],
      availableDrivers: [],
      availableStRoutes: [],
      isStRoutesLoading: false,
      routeSelections: [{
        text: 'Automatic',
        value: null
      }],
      customRouteSelections: [{
        text: 'Automatic',
        value: null
      }],
      routeTypes: [
        { text: 'Primary', value: 'primary' },
        { text: 'Primary (Return)', value: 'primaryReturn' },
        { text: 'Weekday', value: 'weekday' },
        { text: 'Weekday (Return)', value: 'weekdayReturn' }
      ],
      weekdays: [
        { text: 'Monday', value: 1 },
        { text: 'Tuesday', value: 2 },
        { text: 'Wednesday', value: 3 },
        { text: 'Thursday', value: 4 },
        { text: 'Friday', value: 5 },
        { text: 'Saturday', value: 6 },
        { text: 'Sunday', value: 0 }
      ],
      currentVehicleError: null,
      vehicleMetadataType: null,
      vehicleMetadataColor: null,
      vehicleMetadataEngine: null,
      vehicleMetadataPassengerLoad: null,
      otherMetadata: {},
      isEquipmentListLoading: false,
      availableEquipmentSearch: '',
      toggleSelectedAvailableEquipment: false,
      routeEditorLoaded: false,
      routeIdListFromMap: null,
      modalQrcode: false,
      qrCodeErrorOccurred: '',
      quickpayVehicleId: '',
      quickpayUrl: '',
      quickpayHomeUrl: '',
      quickpayQrcodeUrl: '',
      quickpayStickerFilename: '',
      quickpayShortcodeId: '',
      featurePhoneExample: featurePhoneImage,
      smartPhoneExample: smartPhoneImage,
      logoImage
    }
  },
  mounted: function () {
    async function onRouterLoad () {
      const { vehicleId, hasUserType, hasTransportationRole } = this.$props
      let parsedVehicleId = vehicleId || this.$router.currentRoute.params.vehicleId

      if (parsedVehicleId && parsedVehicleId !== '0') {
        await this.getVehicle(parsedVehicleId)
        this.searchStRoutes()
      } else if (hasUserType('admin') || hasTransportationRole('ADMIN')) {
        if (!this.$props.selectedAccount) {
          addAlert({ message: 'Please select an account (in accounts) before creating a vehicle', type: 'error', isModal: true })

          if (this.prevRoute) {
            this.$router.replace({ name: this.prevRoute.name, params: this.prevRoute.params })
          }

          return
        }

        this.generateVehicleTemplate()
        return this.searchStRoutes()
      } else {
        return addAlert({ message: 'Please provide a valid vehicle id', type: 'error', isModal: true })
      }

      // retrieve list of all drivers
      if (this.currentVehicle) {
        const drivers = await findTransportationOwnerUsers({
          where: { transportationOwnerId: this.currentVehicle.transportationOwnerProfile.transportationOwnerId, verified: true },
          include: ['transportationDriverProfile', 'transportationAssistantProfile', 'stUser']
        })

        this.availableDrivers = drivers.map(o => {
          const isAttachedToCurrentDriver = (o.transportationDriverProfile && o.transportationDriverProfile.id === parsedVehicleId)
          const isAttachedToCurrentAssistant = (o.transportationAssistantProfile && o.transportationAssistantProfile.id === parsedVehicleId)
          let currentlyAttachedTo = ''

          if (!isAttachedToCurrentDriver) {
            currentlyAttachedTo = o.transportationDriverProfile ? ` - Driver for: ${o.transportationDriverProfile.licensePlateNumber}` : ''
          }

          if (!isAttachedToCurrentAssistant) {
            currentlyAttachedTo = o.transportationAssistantProfile ? ` - Assistant for: ${o.transportationAssistantProfile.licensePlateNumber}` : ''
          }

          return { text: `${o.stUser.firstName || ''} ${o.stUser.lastName || ''}${currentlyAttachedTo}`, value: o.id }
        })
      }
    }

    this.$nextTick(onRouterLoad.bind(this))
  },
  watch: {
    availableEquipmentSearch (val) {
      this.availableEquipmentSearchQuery(val)
    },
    toggleSelectedAvailableEquipment (val) {
      if (!val) {
        this.currentVehicle.selectedAvailableEquipment = null
      }
    },
    availableStRoutes (routes) {
      this.routeSelections = this.routeSelections.slice(0, 1)

      if (routes?.length) {
        this.routeSelections = this.routeSelections.concat(routes.map(o => {
          return { text: o.label, value: o.id }
        }))
      }
    }
  },
  methods: {
    async searchStRoutes ({ keywords } = {}) {
      try {
        this.isStRoutesLoading = true
        this.availableStRoutes = await findStRoutes({ keywords, order: 'dateUpdated DESC', agencyId: this.currentVehicle.agencyId })
        return this.availableStRoutes
      } catch (err) {
        addAlert({
          message: err,
          type: 'error'
        })
      } finally {
        this.isStRoutesLoading = false
      }
    },
    async viewQrCode () {
      this.qrCodeErrorOccurred = ''
      this.$data.quickpayStickerFilename = `${this.currentVehicle.licensePlateNumber}-quickpay.png`
      this.$data.quickpayUrl = process.env.VUE_APP_QUICKPAY_URL
      this.$data.quickpayHomeUrl = `${this.quickpayUrl}/scan/`.replace('https://', '')
      // this.$data.quickpayVehicleId = this.currentVehicle.id.substring(this.currentVehicle.id.length - 7)
      this.$data.quickpayShortcodeId = this.currentVehicle.shortcodeId
      this.$data.quickpayQrcodeUrl = `${this.$data.quickpayUrl}/en-gb/pay/${encodeURIComponent(this.$data.quickpayShortcodeId)}`
      await this.initQrCode(this.$data.quickpayQrcodeUrl)
      this.$data.modalQrcode = true
    },
    async initQrCode (data) {
      const errorCorrectionLevel = 'H'
      const canvasElement = this.$refs.qrCode

      QRCode.toCanvas(canvasElement, data, {
        errorCorrectionLevel,
        width: 180,
        color: {
          dark: '#000'
        }
      }).catch((err) => {
        this.qrCodeErrorOccurred = 'There was an error generating the QR Code for this vehicle:<br />' + (err.message ? err.message : JSON.stringify(err))
      })
    },
    async downloadQuickpaySticker () {
      try {
        const canvas = await html2canvas(this.$refs.qrCodeContainer)
        return canvas.toDataURL('image/png')
      } catch (err) {
        this.qrCodeErrorOccurred = 'There was an error downloading the sticker:<br />' + (err.message ? err.message : JSON.stringify(err))
        throw new Error(this.qrCodeErrorOccurred)
      }
    },
    isDataEdited () {
      return !_isEqual(this.currentVehicle, this.currentVehicleOriginal)
    },
    availableEquipmentQuery (keywords) {
      this.isEquipmentListLoading = true

      let filter = {
        order: ['dateUpdated DESC', 'dateCreated DESC'],
        include: 'transportationProfile',
        where: { or: [
          { deviceId: { ilike: `%${keywords.replace(/[\s]{2,}/g, ' ').replace(' ', '%')}%` } },
          { osVersion: { ilike: `%${keywords.replace(/[\s]{2,}/g, ' ').replace(' ', '%')}%` } },
          { inventoryId: { ilike: `%${keywords.replace(/[\s]{2,}/g, ' ').replace(' ', '%')}%` } },
          { hotspotSsid: { ilike: `%${keywords.replace(/[\s]{2,}/g, ' ').replace(' ', '%')}%` } }
        ] }
      }

      findTransportationEquipment(filter).then((results) => {
        this.availableEquipment = results.filter(o => (!o.transportationProfile)).map(o => {
          let text = []
          if (o.inventoryId) {
            text.push('(inventory id) ' + o.inventoryId)
          }
          if (o.deviceId) {
            text.push('(device id) ' + o.deviceId)
          }
          return {
            text: text.join(', '),
            value: o
          }
        })
      }).catch((err) => {
        addAlert({
          message: err,
          type: 'error'
        })
      }).finally(() => {
        this.isEquipmentListLoading = false
      })
    },
    availableEquipmentSearchQuery: _debounce(function (val) {
      if (val && this.currentVehicle) {
        this.availableEquipmentQuery(val)
      }
    }, 800),
    availableEquipmentChangeHandle (val) {
      if (val) {
        this.currentVehicle.selectedAvailableEquipment = val
        this.toggleSelectedAvailableEquipment = true
      } else {
        this.currentVehicle.selectedAvailableEquipment = null
        this.toggleSelectedAvailableEquipment = false
      }
    },
    save () {
      if (this.currentVehicle.id) {
        this.updateVehicle()
      } else {
        this.createVehicle()
      }
    },
    async disconnectEquipment () {
      if (confirm('Confirm disconnecting device: ' + this.currentVehicle.transportationEquipment.deviceId + ' from this vehicle')) {
        try {
          this.apiInProgress = true

          if (!this.currentVehicle.transportationOwnerProfile?.transportationOwnerId) {
            throw new Error('Please select a transportation account before disconnecting.')
          }

          await disconnectTransportationEquipment({
            transportationEquipmentId: this.currentVehicle.transportationEquipmentId,
            transportationOwnerId: this.currentVehicle.transportationOwnerProfile.transportationOwnerId
          })

          this.currentVehicle.transportationEquipmentId = null
          this.currentVehicle.transportationEquipment = null

          if (this.currentVehicle.selectedAvailableEquipment) {
            this.currentVehicle.selectedAvailableEquipment = null
          }

          this.currentVehicleOriginal.transportationEquipmentId = null
          this.currentVehicleOriginal.transportationEquipment = null
          this.apiInProgress = false

          addAlert({
            message: 'Successfully disconnected vehicle equipment',
            type: 'success',
            transient: true
          })
        } catch (err) {
          console.log('err', err)
          this.apiInProgress = false

          addAlert({
            message: err,
            type: 'error'
          })
        }
      }
    },
    async registerEquipment () {
      try {
        const selectedAccount = this.$props.selectedAccount || { id: this.currentVehicle.transportationOwnerProfile.transportationOwnerId }
        this.apiInProgress = true

        await registerTransportationEquipment({
          id: this.currentVehicle.selectedAvailableEquipment.id,
          transportationOwnerId: selectedAccount.id,
          transportationProfileId: this.currentVehicle.id
        })

        this.apiInProgress = false
      } catch (err) {
        this.apiInProgress = false
        this.$store.commit(C_ADD_SITE_ALERT, {
          message: `Error in registering equipment: ${err && err.error.message ? err.error.message : JSON.stringify(err)}`,
          type: 'error'
        })
      }
    },
    async updateVehicle () {
      if (this.isFormValid() && this.isDataEdited()) {
        try {
          if (this.currentVehicle.selectedAvailableEquipment && this.currentVehicle.selectedAvailableEquipment.id !== this.currentVehicleOriginal.transportationEquipmentId) {
            await this.registerEquipment()
          }

          if (this.currentVehicle.metadata.backupEquipmentNumber) {
            this.currentVehicle.metadata.backupEquipmentNumber = '+' + this.currentVehicle.metadata.backupEquipmentNumber.replace(/[^0-9]+/g, '')
          }

          this.apiInProgress = true

          let vehicle = await updateTransportationProfile({
            id: this.currentVehicle.id,
            transportType: this.currentVehicle.transportType,
            licensePlateNumber: this.currentVehicle.licensePlateNumber,
            agencyId: this.currentVehicle.agencyId,
            currentDriver: this.currentVehicle.currentDriver || null,
            currentAssistant: this.currentVehicle.currentAssistant || null,
            isRouteFree: !this.currentVehicle.usesRoutes,
            metadata: this.currentVehicle.metadata,
            hoursOfOperationTemplateId: this.currentVehicle.hoursOfOperationTemplateAndData.templateId,
            hoursOfOperationData: {
              timezone: this.currentVehicle.hoursOfOperationTemplateAndData.timezone,
              hours: this.currentVehicle.hoursOfOperationTemplateAndData.hours
            }
          })

          await this.getVehicle(vehicle.id)
          addAlert({ message: `Successfully updated vehicle: ${vehicle.licensePlateNumber}`, type: 'success', transient: true })
        } catch (err) {
          console.log('err', err)
          addAlert({ message: err, type: 'error' })
        } finally {
          this.apiInProgress = false
        }
      }
    },
    createVehicle () {
      if (this.isFormValid() && this.isDataEdited()) {
        let { selectedAccount } = this.$props
        selectedAccount = selectedAccount || { id: this.currentVehicle.transportationOwnerProfile.transportationOwnerId }
        this.apiInProgress = true

        if (this.currentVehicle.metadata.backupEquipmentNumber) {
          this.currentVehicle.metadata.backupEquipmentNumber = '+' + this.currentVehicle.metadata.backupEquipmentNumber.replace(/[^0-9]+/g, '')
          if (this.currentVehicle.metadata.backupEquipmentNumber.length <= 4) {
            this.currentVehicle.metadata.backupEquipmentNumber = ''
          }
        }

        createTransportationProfile({
          transportationOwnerId: selectedAccount.id,
          data: {
            transportType: this.currentVehicle.transportType,
            licensePlateNumber: this.currentVehicle.licensePlateNumber,
            agencyId: this.currentVehicle.agencyId,
            // currentDriver: this.currentVehicle.currentDriver || null,
            // currentAssistant: this.currentVehicle.currentAssistant || null,
            // transportationEquipmentId,
            isRouteFree: !this.currentVehicle.usesRoutes,
            metadata: this.currentVehicle.metadata,
            hoursOfOperationTemplateId: this.currentVehicle.hoursOfOperationTemplateAndData.templateId,
            hoursOfOperationData: this.currentVehicle.hoursOfOperationTemplateAndData && this.currentVehicle.hoursOfOperationTemplateAndData.timezone ? {
              timezone: this.currentVehicle.hoursOfOperationTemplateAndData.timezone,
              hours: this.currentVehicle.hoursOfOperationTemplateAndData.hours
            } : undefined
          }
        }).then((vehicle) => {
          this.$router.push({ params: { vehicleId: vehicle.id } })
          this.$props.forceRootViewRefresh()

          this.$store.commit(C_ADD_SITE_ALERT, {
            message: `Successfully created vehicle: ${vehicle.licensePlateNumber}`,
            type: 'success',
            transient: true
          })
        }).catch((err) => {
          this.$store.commit(C_ADD_SITE_ALERT, {
            message: `Error in creating vehicle: ${err && err.error.message ? err.error.message : JSON.stringify(err)}`,
            type: 'error'
          })
        }).finally(() => {
          this.apiInProgress = false
        })
      } else {
        alert('Please check for invalid fields')
      }
    },
    loadParentView () {
      if (!this.isDataEdited() || confirm('Discard changes made?')) {
        this.$router.push({ name: 'transportation-accounts-vehicles' }, () => (this.$props.forceRootViewRefresh()))
      }
    },
    refreshView () {
      this.$router.go()
    },
    generateVehicleTemplate () {
      this.setCurrentVehicle({
        transportType: 'smarttransit_bus',
        agencyId: 'STRANSITGHA',
        licensePlateNumber: '',
        currentDriver: null,
        currentAssistant: null,
        transportationEquipmentId: null,
        metadata: {},
        isRouteFree: true
      })
    },
    isFormValid () {
      return this.$refs && this.$refs.form && this.$refs.form.validate()
    },
    setCurrentVehicle (vehicle) {
      const { hasUserType, hasTransportationRole } = this.$props

      if (vehicle.transportationEquipment && vehicle.transportationEquipment.inventoryId) {
        vehicle.idLabel = `(inventory id) ${vehicle.transportationEquipment.inventoryId}`
      } else if (vehicle.transportationEquipment && vehicle.transportationEquipment.deviceId) {
        vehicle.idLabel = `(device id) ${vehicle.transportationEquipment.deviceId}`
      } else if (vehicle.transportationEquipment) {
        vehicle.idLabel = `(id) ${vehicle.transportationEquipment.id}`
      }

      vehicle.currentDriverLabel = vehicle.currentDriverObj ? `${vehicle.currentDriverObj.firstName || ''} ${vehicle.currentDriverObj.lastName || ''}` : 'n/a'
      vehicle.currentAssistantLabel = vehicle.currentAssistantObj ? `${vehicle.currentAssistantObj.firstName || ''} ${vehicle.currentAssistantObj.lastName || ''}` : 'n/a'
      vehicle.dateCreatedLabel = vehicle.dateCreated ? `${formatDate(vehicle.dateCreated)}, ${formatTime(vehicle.dateCreated)}` : ''
      vehicle.dateUpdatedLabel = vehicle.dateUpdated ? `${formatDate(vehicle.dateUpdated)}, ${formatTime(vehicle.dateUpdated)}` : ''
      vehicle.isEditable = hasUserType('staff') || hasTransportationRole('ADMIN')
      vehicle.transportationProfileRoutesCount = vehicle.transportationProfileRoutes ? vehicle.transportationProfileRoutes.length : 0
      vehicle.usesRoutes = !vehicle.isRouteFree
      vehicle.metadata = vehicle.metadata || {}

      vehicle.metadata = Object.assign({ vehicle: {
        type: '',
        color: '',
        engine: '',
        passengerLoad: 0
      },
      other: {},
      transportationProfileRouteIdOverride: null,
      stRouteIdOverride: null,
      ignoreBackupEquipment: null,
      backupEquipmentNumber: ''
      }, vehicle.metadata)

      if (vehicle.metadata.backupEquipmentNumber) {
        vehicle.backupEquipmentNumberPrefix = vehicle.metadata.backupEquipmentNumber.substring(0, vehicle.metadata.backupEquipmentNumber.length - 10)
      }

      vehicle.hoursOfOperationTemplateAndData = {
        templateId: vehicle.hoursOfOperationTemplateId,
        ...vehicle.hoursOfOperationData
      }

      this.currentVehicle = vehicle
      this.currentVehicleOriginal = _cloneDeep(vehicle)

      if (this.currentVehicle?.transportationProfileRoutes?.length) {
        const customProfileRoutes = this.currentVehicle.transportationProfileRoutes
        this.customRouteSelections = this.customRouteSelections.slice(0, 1)

        if (customProfileRoutes.length) {
          this.customRouteSelections = this.customRouteSelections.concat(customProfileRoutes.map(o => {
            let label = o.stRoute?.label || o.profileRouteMetadata?.routeLabel || `Unknown ${o.id}`
            label = `${this.getRouteTypeLabel(o)} – ${label}`
            return { text: label, value: o.id }
          }))
        }
      }
    },
    getRouteTypeLabel (transportationProfileRoute) {
      let routeType = ''

      if (transportationProfileRoute.isPrimary) {
        routeType = this.routeTypes.find((o) => (o.value === 'primary')).text
      } else if (transportationProfileRoute.isPrimaryReturn) {
        routeType = this.routeTypes.find((o) => (o.value === 'primaryReturn')).text
      } else if (transportationProfileRoute.weekday) {
        routeType = this.routeTypes.find((o) => (o.value === 'weekday')).text

        if (transportationProfileRoute.isReturn) {
          routeType = this.routeTypes.find((o) => (o.value === 'weekdayReturn')).text
        }

        let weekdayLabel = this.weekdays.find((rt) => transportationProfileRoute.weekday === rt.value)
        weekdayLabel = weekdayLabel ? weekdayLabel.text : ''
        routeType += `${routeType} (${weekdayLabel})`
      }

      return routeType
    },
    getVehicle (id) {
      this.apiInProgress = true

      return getTransportationProfile({
        id,
        filter: {
          include: [
            { transportationProfileRoutes: 'stRoute' },
            'transportationEquipment',
            'currentDriverObj',
            'currentAssistantObj',
            'transportationOwnerProfile'
          ]
        }
      }).then((vehicle) => {
        return this.setCurrentVehicle(vehicle)
      }).catch((err) => {
        console.log('setCurrentVehicle', err)
        this.currentVehicleError = `Vehicle could not be loaded: ${err && err.error ? err.error.message : JSON.stringify(err)}`
      }).finally(() => {
        this.apiInProgress = false
      })
    },
    removeVehicleMetadata (property) {
      if (this.currentVehicle.metadata.vehicle[property]) {
        this.currentVehicle.metadata.vehicle[property] = ''
      }
    },
    removeMetadata (property) {
      if (property.toLowerCase() === 'vehicle') {
        alert('Cannot remove the reserved property "vehicle"')
      } else if (!_isNil(this.currentVehicle.metadata.other[property])) {
        this.$delete(this.currentVehicle.metadata.other, property)
      }
    },
    addMetadata () {
      const property = prompt('Add a name for your entry')
      if (property && ['vehicle', 'other', 'ignoreBackupEquipment', 'backupEquipmentNumber'].includes(property.toLowerCase())) {
        return alert(`"${property}" is a reserved keyword`)
      }
      if (property && Object.keys(this.currentVehicle.metadata).map(o => o.toLowerCase()).indexOf(property) > -1) {
        return alert(`The name "${property}" is already added`)
      }
      if (property) {
        this.$set(this.currentVehicle.metadata.other, property, '')
      }
    },
    onFilterByTransportType (val) {
      if (this.isSmartTransitVehicle()) {
        this.currentVehicle.usesRoutes = true
      } else {
        this.currentVehicle.usesRoutes = false
      }
    },
    isSmartTransitVehicle () {
      return this.currentVehicle.transportType.indexOf('smarttransit') > -1
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  }
}
